function init_main(fromPhp) {
  "use strict";
  // Full Width Carousel
  $('.owl-slide').owlCarousel({
    nav: true,
    loop: true,
    autoplay: true,
    items: 1
  });

  // Recent Reviews
  $('.owl-list').owlCarousel({
    margin: 25,
    nav: true,
    dots: false,
    responsive: {
      0: {
        items: 1
      },
      500: {
        items: 2
      },
      701: {
        items: 3
      },
      1000: {
        items: 4
      }
    }
  });

  // lightbox
  $('[data-lightbox]').lightbox();
  // Tooltips
  // ======================
  $("[data-toggle='tooltip']").tooltip({
    container: "body"
  });


  // Popovers
  // ======================
  $("[data-toggle='popover']").popover();


  // Sticky Sections
  // ======================
  if ($.fn.sticky) {
    $('section[data-fixed="true"]').sticky({ topSpacing: $('#header').outerHeight(), zIndex: 1039 }).on('sticky-start', function() { $('#header').addClass('no-shadow'); }).on('sticky-end', function() { $('#header').removeClass('no-shadow'); });
  }

  $(window).resize(function() {
    $('.sticky-wrapper').each(function() {
      $(this).css('min-height', $(this).children().outerHeight() );
    });
  });


  // Fixed Navigation
  // ======================
  $(window).scroll(function(){
    if ($(this).scrollTop() > 40) {
      $('body').addClass('header-scroll');
    } else {
      $('body').removeClass('header-scroll');
    }
  });


  // Responsive Navbar
  // ======================
  // Toggle Navbar
  $(".navbar-toggle").click(function () {
    $('body').toggleClass('navbar-open');
    return false;
  });

  // Nav Responsive
  $('#header .navbar-left .nav').clone().prependTo("body").addClass('nav-responsive');

  // Nav Responsive
  $('.nav-responsive .has-dropdown > a').click(function() {
    $(this).parent().toggleClass('open');
    return false;
  });


  // Search Bar
  // ======================
  // Toggle Search
  $("[data-toggle='search']").click(function () {
    $('body').toggleClass('navbar-search-open');
    return false;
  });

  // Close Search
  $(".navbar-search .close").click(function () {
    $('body').removeClass('navbar-search-open');
    return false;
  });


  // Nav Dropdown Open
  // ======================
  $('#header .has-dropdown').hover(function() {
    $(this).addClass('open');
  }, function() {
    $(this).removeClass('open');
  });


  // Progress Bars
  // ======================
  setTimeout(function(){
    $('.progress-loaded .progress-bar').each(function() {
      var me = $(this);
      var perc = me.attr("aria-valuenow");
      var current_perc = 0;
      var progress = setInterval(function() {
        if (current_perc>=perc) {
          clearInterval(progress);
        } else {
          current_perc +=1;
          me.css('width', (current_perc)+'%');
        }
      }, 0);
    });
  },0);


  // Carousel
  // ======================
  // Ken Burns effect
  $('.item-active').removeClass('item-active');

  // Animated Carousel
  function slideranimation( elems ) {
    var animEndEv = 'webkitAnimationEnd animationend';
    elems.each(function () {
      var $this = $(this),
      $animationType = $this.data('animation');
      $this.addClass($animationType).one(animEndEv, function () {
        $this.removeClass($animationType);
      });
    });
  }
  var $fullCarousel = $('.carousel-animated'),
  $firstAnimatingElems = $fullCarousel.find('.carousel-item:first').find("[data-animation ^= 'animated']");
  slideranimation($firstAnimatingElems);
  $fullCarousel.carousel('pause');

  $fullCarousel.on('slide.bs.carousel', function (e) {
    var $animatingElems = $(e.relatedTarget).find("[data-animation ^= 'animated']");
    slideranimation($animatingElems);
  });

  // Full Height
  $('.full-height .carousel-item').each(function () {
    $(this).css('height', $(window).height()  - $('header').outerHeight() );
  });

  $(window).resize(function () {
    $('.full-height .carousel-item').each(function () {
      $(this).css('height', $(window).height()  - $('header').outerHeight() );
    });
  });


  // Embed Player
  // ======================
  $(".embed-responsive i").click(function() {
    $(this).parent().find('.video-play-icon').addClass("animated fadeOut");
    $(this).parent().parent().find('.video-caption').addClass("animated fadeOut");
    $(this).parent().parent().find('img').addClass("animated fadeOut");
    $(this).parent().parent().parent().addClass('played');
    var id = $(this).parent().parent().parent().data("src");
    $(this).parent().append('<iframe class="embed-responsive-item" src="' + id + '" allowfullscreen></iframe>');
  });


  // Model
  // ======================
  $('.modal').on('show.bs.modal', function() {
    $(this).show();
    setModalMaxHeight(this);
  });

  $(window).resize(function() {
    if ($('.modal.show').length != 0) {
      setModalMaxHeight($('.modal.show'));
    }
  });


  // Check All
  // ======================
  $(".table thead input:checkbox").click(function(){
    $('input:checkbox').not(this).prop('checked', this.checked);
  });

  function setModalMaxHeight(element) {
    this.$element     = $(element);
    this.$content     = this.$element.find('.modal-content');
    var borderWidth   = this.$content.outerHeight() - this.$content.innerHeight();
    var dialogMargin  = $(window).width() < 768 ? 20 : 60;
    var contentHeight = $(window).height() - (dialogMargin + borderWidth);
    var headerHeight  = this.$element.find('.modal-header').outerHeight() || 0;
    var footerHeight  = this.$element.find('.modal-footer').outerHeight() || 0;
    var maxHeight     = contentHeight - (headerHeight + footerHeight);

    this.$content.css({
      'overflow': 'hidden'
    });

    this.$element.find('.modal-body').css({
      'max-height': maxHeight,
      'overflow-y': 'auto'
    });
  }

  $(".setCompanyNameButton").on("click",function (e) {
    setCompanyNameToTextarea();
  });
  $(".setCompanyNameForm").keypress(event => {
    if(event.keyCode == 13) {
      setCompanyNameToTextarea();
    }
  });

  function setCompanyNameToTextarea() {
    var th = $(".setCompanyNameForm"), data = th.find("input").val(),games = $(".gameGenerateLink,.iframeTutorial"),needLength = 4;
    data = data.replace(/[^a-z0-9\s]/gi, '').replace(/[_\s]/g, '-');
    th.find("input").val(data);
    if(typeof data == "undefined" || data == "" || data.length < needLength) {
      th.prepend('<div class="alert alert-danger">Type at least '+needLength+' characters!</div>');
      return false;
    }
    th.find(".alert").remove();
    games.each(function(){
      const th = $(this), textMain = th.attr("data-url");
      let text = "";
      text = textMain.replace("#partner#",data);

      th.val(text);
      $('.tutorial').show();
    });
    // th.find('.close').trigger("click");
    // $(".gamesHref").off().on("click",gotodiv)
  }


  function gotodiv(id){
    $('html, body').animate({
      scrollTop: $(id).offset().top
    }, 600);
  }

  $(".gamesHref").off().on("click",()=>{
    gotodiv("#viewGamesWithLinks");
    $("body").removeClass("navbar-open");
  })
  $(".cardAll").each(function () {
    var card = $(this),textarea = card.find(".gameLinkView"), copyButton = card.find(".copyLink");

    copyButton.on("click",function () {
      // textarea.select();
      // textarea.setSelectionRange(0,99999);
      //
      // document.execCommand("copy");

      var link = textarea.attr("data-url");
      if($('.tutorial').is(":visible")){
        link = textarea.val();
      }
      var iframeTutorial = $(".iframeTutorial");
      var iframe = `<iframe src="${link}" width="1280" height="780"></iframe>`;
      if($('.tutorial').is(":visible")){
        iframeTutorial.val(iframe);
      }else{
        iframeTutorial.attr("data-url",iframe);
      }
      $(".setCompanyNameForm").show();
      gotodiv("#viewGamesWithLinks");
    })
  });

  $(".copyIframe").on("click",function () {
    var input =  $(".iframeTutorial");
    input.select();
    document.execCommand("copy");
  })


  var carouselBackup;
  $(".playGame").off().on("click",function(){
    var th=$(this),mainContainer = $(".gameContainer"),iframe=$("<iframe></iframe>"),url=th.attr("href");

    if(typeof carouselBackup === "undefined"){
      carouselBackup = mainContainer.clone();
    }


    iframe.attr("width",mainContainer.width());
    iframe.attr("height",mainContainer.height());
    iframe.attr("src",url);

    mainContainer.html(iframe);
    $(".closeIframe").show();
    if($("body").hasClass("navbar-open")) {
      $("body").removeClass("navbar-open");
    }
    gotodiv("body");
  });

  $(".closeIframe").on("click",function () {
    var th = $(this),mainContainer = $(".gameContainer");
    mainContainer.html(carouselBackup);

    th.hide();
  });
}
